import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/design/design/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
import { DoDontContainer, Do, Dont, Caption } from '@primer/gatsby-theme-doctocat';
import ScrollToAnchor from '~/src/components/scroll-to-anchor';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <ScrollToAnchor mdxType="ScrollToAnchor" />
    <h2>{`Language`}</h2>
    <p>{`Language is the most important tool at our disposal for creating a clear, understandable product. Having clear language helps us create memorable commands that are clear in what they will do.`}</p>
    <p>{`We generally follow this structure:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`gh`}</th>
          <th parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="th">{`<command>`}</inlineCode></th>
          <th parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="th">{`<subcommand>`}</inlineCode></th>
          <th parentName="tr" {...{
            "align": null
          }}>{`[value]`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`[flags]`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`[value]`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`gh`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`issue`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`view`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`234`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`--web`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`gh`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`pr`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`create`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`--title`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`“Title”`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`gh`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`repo`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`fork`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`cli/cli`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`--clone`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`gh`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`pr`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`status`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`gh`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`issue`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`list`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`--state`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`closed`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`gh`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`pr`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`review`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`234`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`--approve`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
      </tbody>
    </table>
    <p><strong parentName="p">{`Command:`}</strong>{` The object you want to interact with`}</p>
    <p><strong parentName="p">{`Subcommand:`}</strong>{` The action you want to take on that object. Most `}<inlineCode parentName="p">{`gh`}</inlineCode>{` commands contain a command and subcommand. These may take arguments, such as issue/PR numbers, URLs, file names, OWNER/REPO, etc.`}</p>
    <p><strong parentName="p">{`Flag:`}</strong>{` A way to modify the command, also may be called “options”. You can use multiple flags. Flags can take values, but don’t always. Flags always have a long version with two dashes `}<inlineCode parentName="p">{`(--state)`}</inlineCode>{` but often also have a shortcut with one dash and one letter `}<inlineCode parentName="p">{`(-s)`}</inlineCode>{`. It’s possible to chain shorthand flags: `}<inlineCode parentName="p">{`-sfv`}</inlineCode>{` is the same as `}<inlineCode parentName="p">{`-s -f -v`}</inlineCode></p>
    <p><strong parentName="p">{`Values:`}</strong>{` Are passed to the commands or flags`}</p>
    <ul>
      <li parentName="ul">{`The most common command values are:`}
        <ul parentName="li">
          <li parentName="ul">{`Issue or PR number`}</li>
          <li parentName="ul">{`The “owner/repo” pair`}</li>
          <li parentName="ul">{`URLs`}</li>
          <li parentName="ul">{`Branch names`}</li>
          <li parentName="ul">{`File names`}</li>
        </ul>
      </li>
      <li parentName="ul">{`The possible flag values depend on the flag:`}
        <ul parentName="li">
          <li parentName="ul"><inlineCode parentName="li">{`--state`}</inlineCode>{` takes `}<inlineCode parentName="li">{`{closed | open | merged}`}</inlineCode></li>
          <li parentName="ul"><inlineCode parentName="li">{`--clone`}</inlineCode>{` is a boolean flag`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`--title`}</inlineCode>{` takes a string`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`--limit`}</inlineCode>{` takes an integer`}</li>
        </ul>
      </li>
    </ul>
    <p><em parentName="p">{`Tip: To get a better sense of what feels right, try writing out the commands in the CLI a few different ways.`}</em></p>
    <DoDontContainer mdxType="DoDontContainer">
  <Do mdxType="Do">
    <img alt="" src="https://user-images.githubusercontent.com/980622/215497420-8ab255c3-62b4-42d7-a126-028984471cb2.png" width="100%" />
    <Caption mdxType="Caption">Use a flag for modifiers of actions</Caption>
  </Do>
  <Dont mdxType="Dont">
    <img alt="" src="https://user-images.githubusercontent.com/980622/215497413-06bb4d3c-e543-45d1-99f4-9e2cfc218179.png" width="100%" />
    <Caption mdxType="Caption">Avoid making modifiers their own commands</Caption>
  </Dont>
    </DoDontContainer>
    <p><strong parentName="p">{`When designing your command’s language system:`}</strong></p>
    <ul>
      <li parentName="ul">{`Use `}<a parentName="li" {...{
          "href": "/native/cli/getting-started#make-it-feel-like-github"
        }}>{`GitHub language`}</a></li>
      <li parentName="ul">{`Use unambiguous language that can’t be confused for something else`}</li>
      <li parentName="ul">{`Use shorter phrases if possible and appropriate`}</li>
    </ul>
    <DoDontContainer mdxType="DoDontContainer">
  <Do mdxType="Do">
    <img alt="" src="https://user-images.githubusercontent.com/980622/215497419-5b3785c7-5360-4553-975d-27c492feb5d9.png" width="100%" />
    <Caption mdxType="Caption">Use language that can't be misconstrued</Caption>
  </Do>
  <Dont mdxType="Dont">
    <img alt="" src="https://user-images.githubusercontent.com/980622/215497411-0d5c778e-e03a-49da-9628-d0d34d11c4ec.png" width="100%" />
    <Caption mdxType="Caption">
      Avoid language that can be interpreted in multiple ways ("open in browser" or "open a pull request" here)
    </Caption>
  </Dont>
    </DoDontContainer>
    <DoDontContainer mdxType="DoDontContainer">
  <Do mdxType="Do">
    <img alt="" src="https://user-images.githubusercontent.com/980622/215497417-c8e5d51c-9280-4cae-9b4b-6a3f70b88786.png" width="100%" />
    <Caption mdxType="Caption">Use understood shorthands to save characters to type</Caption>
  </Do>
  <Dont mdxType="Dont">
    <img alt="" src="https://user-images.githubusercontent.com/980622/215497409-678c95f4-0c47-41ac-b81b-f1cadfe69516.png" width="100%" />
    <Caption mdxType="Caption">Avoid long words in commands if there's a reasonable alternative</Caption>
  </Dont>
    </DoDontContainer>
    <h2>{`Typography`}</h2>
    <p>{`Everything in a command line interface is text, so type hierarchy is important. All type is the same size and font, but you can still create type hierarchy using font weight and space.`}</p>
    <img src="https://user-images.githubusercontent.com/980622/215497511-7162553d-a1a7-4703-a640-b349ba06e7bf.png" alt="An example of normal weight, and bold weight. Italics is striked through since it's not used." />
    <ul>
      <li parentName="ul">{`People customize their fonts, but you can assume it will be a monospace`}</li>
      <li parentName="ul">{`Monospace fonts inherently create visual order`}</li>
      <li parentName="ul">{`Fonts may have variable unicode support`}</li>
    </ul>
    <h3>{`Accessibility`}</h3>
    <p>{`If you want to ensure that a screen reader will read a pause, you can use a:`}</p>
    <ul>
      <li parentName="ul">{`period (`}<inlineCode parentName="li">{`.`}</inlineCode>{`)`}</li>
      <li parentName="ul">{`comma (`}<inlineCode parentName="li">{`,`}</inlineCode>{`)`}</li>
      <li parentName="ul">{`colon (`}<inlineCode parentName="li">{`:`}</inlineCode>{`)`}</li>
    </ul>
    <h2>{`Spacing`}</h2>
    <p>{`You can use the following to create hierarchy and visual rhythm:`}</p>
    <ul>
      <li parentName="ul">{`Line breaks`}</li>
      <li parentName="ul">{`Tables`}</li>
      <li parentName="ul">{`Indentation`}</li>
    </ul>
    <DoDontContainer stacked mdxType="DoDontContainer">
  <Do mdxType="Do">
    <img alt="" src="https://user-images.githubusercontent.com/980622/215497385-27dc586e-92c9-414f-9b19-92688f096119.png" />
    <Caption mdxType="Caption">Use space to create more legible output</Caption>
  </Do>
  <Dont mdxType="Dont">
    <img alt="" src="https://user-images.githubusercontent.com/980622/215497381-34fff043-bcc9-4307-9459-f1a549ca399b.png" />
    <Caption mdxType="Caption">Not using space makes output difficult to parse</Caption>
  </Dont>
    </DoDontContainer>
    <h2>{`Color`}</h2>
    <p>{`Terminals reliably recognize the 8 basic ANSI colors. There are also bright versions of each of these colors that you can use, but less reliably.`}</p>
    <img src="https://user-images.githubusercontent.com/980622/215497351-fd41be98-14fc-4b52-b8dc-32fb65f76eb2.png" alt="A table describing the usage of the 8 basic colors." />
    <h3>{`Things to note`}</h3>
    <ul>
      <li parentName="ul">{`Background color is available but we haven’t taken advantage of it yet.`}</li>
      <li parentName="ul">{`Some terminals do not reliably support 256-color escape sequences.`}</li>
      <li parentName="ul">{`Users can customize how their terminal displays the 8 basic colors, but that’s opt-in (for example, the user knows they’re making their greens not green).`}</li>
      <li parentName="ul">{`Only use color to `}<a parentName="li" {...{
          "href": "/components/icon#usage"
        }}>{`enhance meaning`}</a>{`, not to communicate meaning.`}</li>
    </ul>
    <h2>{`Iconography`}</h2>
    <p>{`Since graphical image support in terminal emulators is unreliable, we rely on Unicode for iconography. When applying iconography consider:`}</p>
    <ul>
      <li parentName="ul">{`People use different fonts that will have varying Unicode support`}</li>
      <li parentName="ul">{`Only use iconography to `}<a parentName="li" {...{
          "href": "/components/icon#usage"
        }}>{`enhance meaning`}</a>{`, not to communicate meaning`}</li>
    </ul>
    <p><em parentName="p">{`Note: In Windows, Powershell’s default font (Lucida Console) has poor Unicode support. Microsoft suggests changing it for more Unicode support.`}</em></p>
    <p><strong parentName="p">{`Symbols currently used:`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`✓   Success
-   Neutral
✗   Failure
+   Changes requested
!   Alert
`}</code></pre>
    <DoDontContainer mdxType="DoDontContainer">
  <Do mdxType="Do">
    <img alt="" src="https://user-images.githubusercontent.com/980622/215497402-6d7a375c-9530-4f8b-82e1-06653f42e15b.png" width="100%" />
    <Caption mdxType="Caption">Use checks for success messages</Caption>
  </Do>
  <Dont mdxType="Dont">
    <img alt="" src="https://user-images.githubusercontent.com/980622/215497405-ce95e7f7-574c-4aae-ae52-0c4ef2c6b050.png" width="100%" />
    <Caption mdxType="Caption">Don't use checks for failure messages</Caption>
  </Dont>
    </DoDontContainer>
    <DoDontContainer mdxType="DoDontContainer">
  <Do mdxType="Do">
    <img alt="" src="https://user-images.githubusercontent.com/980622/215497406-95470c4c-7d90-4254-b7a2-39371eee716c.png" width="100%" />
    <Caption mdxType="Caption">Use checks for success of closing or deleting</Caption>
  </Do>
  <Dont mdxType="Dont">
    <img alt="" src="https://user-images.githubusercontent.com/980622/215497407-373927e0-ab3b-44e6-846d-1d1d70bc6c01.png" width="100%" />
    <Caption mdxType="Caption">Don't use alerts when closing or deleting</Caption>
  </Dont>
    </DoDontContainer>
    <h2>{`Scriptability`}</h2>
    <p>{`Make choices that ensure that creating automations or scripts with GitHub commands is obvious and frictionless. Practically, this means:`}</p>
    <ul>
      <li parentName="ul">{`Create flags for anything interactive`}</li>
      <li parentName="ul">{`Ensure flags have clear language and defaults`}</li>
      <li parentName="ul">{`Consider what should be different for terminal vs machine output`}</li>
    </ul>
    <h3>{`In terminal`}</h3>
    <img src="https://user-images.githubusercontent.com/980622/215497378-70bbe5b1-74dc-4b95-a329-c8f7e2908bae.png" alt="An example of gh pr list" />
    <h3>{`Through pipe`}</h3>
    <img src="https://user-images.githubusercontent.com/980622/215497375-a872df76-840a-489b-b0ed-61e37afed9c8.png" alt="An example of gh pr list piped through the cat command" />
    <h3>{`Differences to note in machine output`}</h3>
    <ul>
      <li parentName="ul">{`No color or styling`}</li>
      <li parentName="ul">{`State is explicitly written, not implied from color`}</li>
      <li parentName="ul">{`Tabs between columns instead of table layout, since `}<inlineCode parentName="li">{`cut`}</inlineCode>{` uses tabs as a delimiter`}</li>
      <li parentName="ul">{`No truncation`}</li>
      <li parentName="ul">{`Exact date format`}</li>
      <li parentName="ul">{`No header`}</li>
    </ul>
    <h2>{`Customizability`}</h2>
    <p>{`Be aware that people exist in different environments and may customize their setups. Customizations include:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Shell:`}</strong>{` shell prompt, shell aliases, PATH and other environment variables, tab-completion behavior`}</li>
      <li parentName="ul"><strong parentName="li">{`Terminal:`}</strong>{` font, color scheme, and keyboard shortcuts`}</li>
      <li parentName="ul"><strong parentName="li">{`Operating system`}</strong>{`: language input options, accessibility settings`}</li>
    </ul>
    <p>{`The CLI tool itself is also customizable. These are all tools at your disposal when designing new commands.`}</p>
    <ul>
      <li parentName="ul">{`Aliasing: `}<a parentName="li" {...{
          "href": "https://cli.github.com/manual/gh_alias_set"
        }}><inlineCode parentName="a">{`gh alias set`}</inlineCode></a></li>
      <li parentName="ul">{`Preferences: `}<a parentName="li" {...{
          "href": "https://cli.github.com/manual/gh_config_set"
        }}><inlineCode parentName="a">{`gh config set`}</inlineCode></a></li>
      <li parentName="ul">{`Environment variables: `}<inlineCode parentName="li">{`NO_COLOR`}</inlineCode>{`, `}<inlineCode parentName="li">{`EDITOR`}</inlineCode>{`, etc`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      